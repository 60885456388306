:root {
  --rs-primary-50: #ffeeed;
  --rs-primary-100: #f0bcb9;
  --rs-primary-200: #e09089;
  --rs-primary-300: #cf685d;
  --rs-primary-400: #bf4737;
  --rs-primary-500: #b02c17;
  --rs-primary-600: #ab2811;
  --rs-primary-700: #a1240b;
  --rs-primary-800: #911f06;
  --rs-primary-900: #7d1a01;
}

body {
  font-family: "Open Sans", helvetica, arial, sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Confirm modal custom width */
.rs-modal-xs {
  width: 450px;
}

.rs-modal-xs .rs-modal-title label {
  font-size: 16px;
}

.rs-modal-xs .rs-modal-header {
  margin: 0 24px !important;
}

.rs-modal-xs .rs-modal-header-close {
  right: 24px !important;
}
