.strong {
  font-weight: bolder;
}

.flexed-chart-one-section {
  display: flex;
  height: 350px;
  gap: 21px;
  padding: 20px 0;
}

.chart-one-doughnut-container {
  height: 100%;
  width: 34%;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
}

.chart-one-bar-container {
  height: 100%;
  width: 66%;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
}

.flexed-chart-two-section {
  display: flex;
  height: 300px;
  gap: 22px;
  padding: 20px 0;
}

.chart-two-bar-container {
  height: 100%;
  width: 32%;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
}

.flexed-chart-three-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  padding: 20px 0;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
}
